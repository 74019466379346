@import '../../marketplace.css';

.tabList {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px var(--matterColor);
}

.tab {
  width: 120px;
  text-align: center;
}

.selectedTab {
  border: solid 1px var(--matterColor);
  border-bottom: solid 1px var(--matterColorLight);
  margin-bottom: -1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.field {
  margin-bottom: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.tabPanel {
  padding: 12px 24px 40px 24px;

  border: solid 1px var(--matterColor);
  border-top: none;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

:global .react-tabs {
  -webkit-tap-highlight-color: transparent;
}

:global .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

:global .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

:global .react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

:global .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

:global .react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

:global .react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

:global .react-tabs__tab-panel {
  display: none;
}

:global .react-tabs__tab-panel--selected {
  display: block;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;
  }
}

.actionButton {
  @media (--viewportLarge) {
    flex: 1;
    margin-right: 24px;
  }
}

.actionButton:last-child {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}
