@import '../../marketplace.css';

.weekList {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding: 0 36px;
  }
}

.mobileListingList {
  padding: 15px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.week {
  @media (--viewportMedium) {
    width: calc((100% - 90px) / 7);
  }
}

.dateLabel {
  text-align: center;
  font-weight: var(--fontWeightSemiBold);
  margin-left: 24px;
  padding-bottom: 5px;
}

.selectedLabel {
  border-bottom: 2px solid var(--matterColorDark);

  @media (--viewportMedium) {
    border: none;
  }
}

.disabled {
  color: var(--matterColorAnti);
}

.listingResult {
  background-color: var(--marketplaceColorLight);
  color: var(--matterColorLight);
  padding: 2px 5px;
  border-radius: 2px;
  margin-left: 3px;
}

.classList {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.classListing:nth-child(2n) {
  background-color: var(--marketPlaceColorLightBlur);
}

.dayIsPassed {
  color: var(--matterColorAnti);
  text-align: center;
}
