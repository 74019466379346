@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;
  white-space: pre;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 700px;
  }
}
