@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.category {
  margin-bottom: 50px;
}

.textfield {
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 32px;
  }
}

.hidden {
  display: none !important;
}

.textarea {
  @apply --marketplaceH4FontStyles;
  border-bottom-width: 0;

  /* Avoid text going behind the submit button */
  padding: 22px 52px 25px 0;

  margin: 0 0 24px;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0 0 24px;
  }
  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0 0 32px;
    width: 100%;
  }
}

.textarea textarea {
  box-sizing: border-box;
  padding: 10px;
  height: 100px;
  border: 2px solid var(--matterColorNegative);
  border-radius: 5px;
  margin-top: 10px;
}

.field {
  margin-bottom: 24px;
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 20px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 0px 0 0 0;
  width: auto;
}

.sentSuccess {
  font-size: 14px;
  color: var(--successColor);
}

.checkmark {
  stroke: var(--successColor);
}
