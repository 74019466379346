@import '../../marketplace.css';

.topbar {
  z-index: 1;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.guidanceContainer {
  position: fixed;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
  width: 100%;
  height: 100%;

  background-color: var(--matterColorDarkTransparent);

  @media (--viewportMedium) {
    padding: 100px;
  }
}

.guidanceBoxWrapper {
  position: relative;
  padding: 50px;

  display: flex;
  flex-direction: column;

  background-color: var(--matterColorLight);
  border-radius: 5px;

  overflow: scroll;
  max-height: calc(100vh - 100px);

  @media (--viewportLarge) {
    padding: 100px;
    overflow: unset;
    max-height: unset;
    align-items: center;
    justify-content: center;
  }
}

.guidanceTitle {
  @apply --marketplaceH1FontStyles;
  font-size: 24px;
  font-family: 'Aviano Sans';
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin: 0;
  }

  @media (--viewportLarge) {
    font-size: 30px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 36px;
  }
}

.guidances {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guidancesLine::before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid var(--marketplaceColorLight);
  border-right: 250px solid transparent;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    border-right: 400px solid transparent;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    border-right: 700px solid transparent;
    max-width: 1056px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }

  @media (--viewportLargeWithPaddings) {
    border-right: 1000px solid transparent;
    width: 300px;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  margin: 40px 0 80px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportLarge) {
    width: 250px;
    margin-right: 25px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 300px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  font-size: 24px;
  color: var(--matterColor);

  @media (--viewportLarge) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.step > p {
  text-align: center;
  color: var(--matterColorDark);
}

.guidanceNote {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guidanceNote > p {
  margin: 0;
  text-align: center;
}

.guidanceButton {
  position: absolute;
  right: 30px;
  top: 30px;
}

.guidanceButton:hover {
  cursor: pointer;
}
