@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 30px 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.classNav {
  /* position: absolute; */
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
  height: 57px;
}

.tab {
  width: 200px;
  margin-right: 12px;
  cursor: pointer;
}

.selectedTab {
  border: solid 1px var(--matterColor);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-color: var(--matterColorLight);

  & > h3 {
    font-weight: var(--fontWeightSemiBold);
  }
}

.tabLabel {
  @apply --marketplaceH4FontStyles;
  text-align: center;
}

.formWrapper {
  /* position: absolute; */
  top: 56px;
  width: 100%;
  z-index: 0;
  flex: 0;
  border: solid 1px var(--matterColor);
}

.form {
  display: none;
}

.activeForm {
  display: block;
}

.goToNextTabButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 62px;
  }
}
