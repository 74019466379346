@import '../../marketplace.css';

.pageRoot {
  display: flex;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.jitsyContainer {
  width: 100%;
  height: calc(100vh - 60px);

  @media (--viewportMedium) {
    height: calc(100vh - 72px);
  }
}

.hideJitsy {
  display: none;
}

.waitingRoomTitleContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.animatedLogo {
  @apply --backgroundImage;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  @media (--viewportMedium) {
    margin-right: 20px;
    max-width: 150px;
  }
}

.animatedLogo > img {
  width: 60%;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: 20px;
  text-align: center;

  @media (--viewportMedium) {
    align-items: flex-start;
    margin-top: 0;
    text-align: left;
  }
}

.title > h2 {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--marketplaceColor);
}

.title > p {
  margin: 0;
}

span.details {
  text-transform: capitalize;
  font-weight: 600;
  color: var(--marketplaceColorLight);
}

.advertisement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.advertisement > video {
  width: 100%;
}

.popupWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
  position: fixed;
  top: 72px;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: var(--matterColorDarkTransparent);
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px;

  background-color: var(--matterColorLight);
  border-radius: 5px;
  transition: all 5s ease-in-out;
  animation: popup 0.7s;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1.4);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.alert {
  text-align: center;
  color: var(--matterColorDark);
}

.button {
  min-height: 30px;
  padding: 10px 20px;
}

.notificationInMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
  }
}

.iconNotification {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 0;
  width: 50px;
  height: 50px;

  transition: all 5s ease-in-out;
  animation: vibrate 0.2s infinite;

  @media (--viewportMedium) {
    width: 75px;
    height: 75px;
  }
}

@keyframes vibrate {
  0% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

.hidenAudio {
  display: none;
}
