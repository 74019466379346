@import '../../marketplace.css';

.pageTitle {
  position: relative;
  text-align: center;
  color: var(--matterColorLight);
  z-index: 2;
}

.staticPageWrapper {
}

.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
    padding: 0 36px;
  }
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.contentWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
    flex-wrap: nowrap;
  }
}

.contentMain {
  margin: auto;

  @media (--viewportMedium) {
    max-width: 650px;
  }

  @media (--viewportLarge) {
    max-width: 1000px;
  }
}

.space {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 60px;
}

.space::before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 8px solid #d0ab72;
  border-right: 300px solid transparent;
  position: relative;

  @media (--viewportMedium) {
    border-right: 700px solid transparent;
  }

  @media (--viewportLarge) {
    border-right: 1000px solid transparent;
    max-width: 1056px;
  }
}

.contentTitle {
  font-family: 'Aviano Sans';
  margin-top: 0;
  text-align: center;
  color: #5f2244;

  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -1px;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.form {
  max-width: 500px;
  padding: 40px 30px;

  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  @media (--viewportMedium) {
    max-width: 650px;
    width: 600px;
  }

  @media (--viewportLarge) {
    max-width: 1000px;
    width: 800px;
  }
}

.contactInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.contactInfoTitle {
  margin: 0;
  padding: 10px 0 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

/* .contactInfo {
  padding-left: 5px;

  @media (--viewportMedium) {
    padding-left: 10px;
  }
} */

.contactInfo > p {
  margin: 2px 0;
  line-height: unset;
}
