@import '../../marketplace.css';

.root {
  background-color: var(--marketPlaceColorBlur);
  border-radius: 5px;
  padding: 10px;
  color: var(--matterColorLight);
  margin-bottom: 15px;
  display: block;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    text-decoration: none;
    box-shadow: var(--boxShadowNotFoundPageSearch);
  }

  & p {
    margin: 0;
    line-height: 24px;
  }
}

.time {
  font-weight: var(--fontWeightSemiBold);
}

.languages {
  text-transform: capitalize;
}
