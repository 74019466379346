/*
If you need to add css to the Logo you can do it here. 
@import '../../marketplace.css';
.logoMobile {
} 
*/

.root {
  width: 184px;
  height: 49px;
}
