@import '../../marketplace.css';

.pageTitle {
  text-align: center;
  font-family: 'Aviano Sans';
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentSide > p {
  font-family: 'Aviano Sans';
  font-weight: var(--fontWeighSemiBold);
  color: #000;
  font-size: 18px;
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  font-family: 'Aviano Sans';
  margin-top: 32px;
  margin-bottom: 16px;

  font-size: 22px;
}

.signature {
  margin-top: 32px;
  margin-bottom: 16px;
}

.signature > p {
  margin: 3px 0;
}
